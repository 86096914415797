import request from '@/utils/axios.js'
// 操作记录列表
export function Operator (params){
    return request({
        url:'/api/index/info',
        method:'get',
        params
    });
}

// 地区列表
export function District (params){
    return request({
        url:'/api/index/continent',
        method:'get',
        params
    });
}

// 税表提交
export function Taxsb (data){
    return request({
        url:'/api/tax/add',
        method:'post',
        data
    });
}


// 背景调查
export function Shuibiao (data){
    return request({
        url:'/api/background/add',
        method:'post',
        data
    });
}

// 提交住家合同基础信息 生成链接
export function Sharelink (data){
    return request({
        url:'/api/contract/add',
        method:'post',
        data
    });
}

// 提交住家合同信息
export function HomeSubmit (data){
    return request({
        url:'/api/contract/edit',
        method:'post',
        data
    });
}

//         
export function DataHX (data){
    return request({
        url:'/api/contract/info',
        method:'post',
        data
    });
}

// 住家入驻 信息
export function Menu (params){
    return request({
        url:'/api/index/menu',
        method:'get',
        params
    });
}

// 拿到上传token 进行上传住家视频
export function TokenOSS (params){
    return request({
        url:'/api/index/getStsToken',
        method:'get',
        params
    });
}


//住家入驻最终信息提交
export function DataSubmit (data){
    return request({
        url:'/api/house_app/add',
        method:'post',
        data
    });
}


// 住家入驻 save 提交
export function DataSave (data){
    return request({
        url:'api/house_app/tempSave',
        method:'post',
        data
    });
}

//住家入驻 回显
export function HomestayHX(data){
    return request({
        url:'api/house_app/info',
        method:'post',
        data
    });

}
